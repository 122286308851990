import React from "react";
import { AutoLink } from "../helpers/AutoLink";
import { concatClassNames as cn } from "@system42/core";

import * as styles from "./styles.module.css";
import { ReactComponent as SvgCareRight } from "./caret-right.svg";

/**
 * Logo tile that displays an img, and links to the associated
 * organization's website.
 */
export function LogoMiniCard(props) {
  const {
    linkText,
    linkTo,
    linkHref,
    logoSrc,
    logoAlt,
    cssBackground,
    logoWidth,
    logoHeight,
  } = props;

  return (
    <div className={styles.logoMiniCard}>
      <AutoLink className={styles.link} href={linkHref} to={linkTo}>
        <div
          className={styles.card}
          style={{ background: cssBackground || "black" }}
        >
          <img
            src={logoSrc}
            alt={logoAlt}
            width={logoWidth}
            height={logoHeight}
          />
        </div>
        <div className={styles.linkText}>
          {linkText}
          <SvgCareRight className={styles.caret} />
        </div>
      </AutoLink>
    </div>
  );
}

/**
 * Logo tile carousel that scrolls horizontally.
 */
LogoMiniCard.Group = function LogoMiniCardGroup(props) {
  const { className, ...restProps } = props;
  return <div className={cn(styles.group, className)} {...restProps} />;
};
