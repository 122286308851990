import React from "react";
import {
  Headline2Sans,
  HighlightedText,
  concatClassNames as cn,
  urlKnowledgeBase,
} from "../../system42";
import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./prototypes.module.css";
import { ContentCentered } from "../../components/ContentCentered";

import { featureOverviewFeatures } from "../../helpers";
import { ListCentered } from "../../components/ListCentered";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { StaticImage } from "gatsby-plugin-image";
import { LogoMiniCard } from "../../components/LogoMiniCard";
import { TwoColumns } from "../../components/TwoColumns";
import { FeatureOverview } from "../../components/FeatureOverview";

import imageLogoAdobeXD from "./images/logo-adobe-xd.svg";
import imageLogoAxure from "./images/logo-axure.svg";
import imageLogoFigma from "./images/logo-figma.min.png";
import imageLogoFramer from "./images/logo-framer.svg";
import imageLogoInvision from "./images/logo-invision.svg";
import imageLogoSketch from "./images/logo-sketch.svg";
import imageNeedHelp from "./images/needHelp.jpg";

const ContentPage = () => {
  return (
    <Layout
      title={"Userbrain vs. other user testing tools"}
      metaDescription={
        "How does Userbrain compare against the competition? We’ve crunched the numbers, so you don’ t have to.Here’ s how Userbrain stacks up."
      }
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          title={
            <>
              <span className={helperStyles.h1Serif}>User testing</span>{" "}
              prototypes
            </>
          }
          staticImage={(className) => (
            <StaticImage
              className={className}
              src={"./images/user-testing-prototypes.min.jpg"}
              alt={"Easily user test prototypes with Userbrain"}
            />
          )}
        />

        <ContentCentered
          className={helperStyles.spacingLg}
          title={"Testing your prototype with real people is easy."}
          titleWidth={"15em"}
          textContent={
            "Want to test your prototype in the real world? Userbrain is your go-to  user testing tool — perfectly compatible with all prototyping tools."
          }
        />

        <h3 className={styles.headingLearnHow}>
          Learn how to use Userbrain with:
        </h3>

        <div className={styles.logoMiniCardContainer}>
          <LogoMiniCard.Group className={styles.logoMiniCardGroup}>
            <LogoMiniCard
              linkText={"Adobe XD"}
              linkTo={"/user-testing/adobexd-prototype"}
              logoSrc={imageLogoAdobeXD}
              logoAlt={"Adobe XD Logo"}
              cssBackground={"#470137"}
            />
            <LogoMiniCard
              linkText={"Axure"}
              linkTo={"/user-testing/axure-prototype"}
              logoSrc={imageLogoAxure}
              logoAlt={"Axure Logo"}
              cssBackground={
                "linear-gradient(180deg, #F9FBFB 0%, #F1F4F4 100%)"
              }
            />
            <LogoMiniCard
              linkText={"Figma"}
              linkTo={"/user-testing/figma-prototype"}
              logoSrc={imageLogoFigma}
              logoAlt={"Figma Logo"}
              logoWidth={"32px"}
              logoHeight={"73px"}
              cssBackground={"#292626"}
            />
            <LogoMiniCard
              linkText={"Framer"}
              linkTo={"/user-testing/framer-prototype"}
              logoSrc={imageLogoFramer}
              logoAlt={"Framer Logo"}
              cssBackground={
                "linear-gradient(180deg, #00C7FF 0%, #344EFF 61.98%, #4E31FF 100%)"
              }
            />
            <LogoMiniCard
              linkText={"InVision"}
              linkTo={"/user-testing/invision-prototype"}
              logoSrc={imageLogoInvision}
              logoAlt={"InVision Logo"}
              cssBackground={"#F36"}
            />
            <LogoMiniCard
              linkText={"Sketch"}
              linkTo={"/user-testing/sketch-prototype"}
              logoSrc={imageLogoSketch}
              logoAlt={"Sketch Logo"}
              cssBackground={"#FFF4F0"}
            />
          </LogoMiniCard.Group>
        </div>

        <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <ContentCentered
            title={"How it works"}
            textContent={
              "Here’s how to test a prototype with Userbrain in 3 simple steps."
            }
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"Create a Prototype Test"}
            content={
              "From your Userbrain dashboard, create a new test and select the Prototype option. If you need some help creating a user test, browse through Userbrain’s prototype test templates for inspiration, tips, and best practices."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"./images/create-a-prototype-test.min.png"}
                alt={"Create a Prototype Test"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            inverted
            heading={"Enter your prototype link"}
            content={
              "Simply copy the prototype link from your favorite prototyping tool. Not sure how to share your prototype link? We’ve created detailed guides for all major prototyping tools."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"./images/enter-your-prototype-link.min.png"}
                alt={"Enter your prototype link"}
              />
            )}
          />

          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"Order testers"}
            content={
              "Now it’s time to tap into Userbrain’s global pool of over 100k testers. You can target by demographics and devices, before screening for prior experiences, preferences, or habits."
            }
            image={(className) => (
              <StaticImage
                className={className}
                src={"./images/order-testers.min.png"}
                alt={"Order testers for user testing"}
              />
            )}
          />
        </section>

        <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <Headline2Sans className={styles.threeReasonsTitle}>
            3 reasons why you should perform Userbrain tests on prototypes:
          </Headline2Sans>
          <ListCentered
            className={styles.threeReasonsList}
            type={"number"}
            color={"var(--color-secondary-2)"}
            items={[
              {
                title: "Reduce costs",
                text: "Avoid costly mistakes. Save time and money by testing your prototype early.",
              },
              {
                title: "Get an outside perspective",
                text: "Beat design bias. Get unbiased feedback from real people outside of your own circle.",
              },
              {
                title: "Fail fast",
                text: "Discover what works – and what doesn’t – quickly. Reiterate different versions of your prototype and continuously improve your design.",
              },
            ]}
          />
        </section>

        <section
          className={cn(
            helperStyles.spacingLg,
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            title={"All the best features for wireframe and prototype testing."}
            titleWidth={"17em"}
            features={featureOverviewFeatures}
          />
        </section>

        <section className={cn(helperStyles.container, helperStyles.spacingLg)}>
          <TwoColumns
            className={helperStyles.spacingLg}
            heading={"Need a hand?"}
            content={
              "Check out our knowledge base articles about prototype testing with Userbrain. Find out how to set up a Userbrain test with your favorite prototyping tools, discover tips and best practices, and more."
            }
            linkHref={urlKnowledgeBase + "/search?keyword=prototype"}
            linkText={"Read more"}
            imgSrc={imageNeedHelp}
            imgAlt={
              "Macbook Pro with knowledge base article about user testing prototypes with Userbrain opened"
            }
          />

          <GetStartedBox
            className={helperStyles.spacingLg}
            title={
              <>
                Ready for your first{" "}
                <HighlightedText cursor>prototype test?</HighlightedText>
              </>
            }
            textBottom={
              "Get set up in minutes. Your first test is ready in a few hours."
            }
          />
        </section>
      </article>
    </Layout>
  );
};

export default ContentPage;
